

import { computed, ref } from 'vue'
import ZaloRepository from "@/services/ZaloRepository";
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import TeamRepository from "../services/TeamRepository";
import {Team} from "@/models/team.models";
import {Message} from "@/models/message";
import {MessageWithAttachment} from "@/models/messageWithAttachment";
import {ElementParamater} from "@/models/elementParamater";
import { ListCustomer } from '@/models/listCustomer';
import Province from "@/models/province.models";
import {ListImage} from "@/models/listImage";
import {ListImageFull} from "@/models/listImageFull";
import {Group} from "@/models/group";

export default {

  setup(){
    const router = useRouter();
    const register = ref({} as Register);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const message = ref({} as Message);
    const messageWithAttachment = ref({} as MessageWithAttachment);
    const element1 = ref({} as ElementParamater);
    const element2 = ref({} as ElementParamater);
    const element3 = ref({} as ElementParamater);
    const element4 = ref({} as ElementParamater);
    const element5 = ref({} as ElementParamater);
    const listId = ref([] as ListCustomer[]);
    const listIdMessage = ref([] as ListCustomer[]);
    const loadingBar = ref(false);
    const action = ref("");
    const gender = ref("");
    const age = ref([10,100]);
    const action2 = ref("");
    const gender2 = ref("");
    const age2 = ref([10,100]);
    const find = ref("");
    const lsAction = ref([
        {label: 'Gởi tin nhắn cho từng khách hàng', value: 'customer'},
        {label: 'Gởi tin nhắn cho tất cả khách hàng', value: 'all'},
        {label: 'Gởi tin nhắn theo giới tính', value: 'gender'},
        {label: 'Gởi tin nhắn theo độ tuổi', value: 'age'},
        {label: 'Gởi tin nhắn theo khu vực', value: 'location'},
        {label: 'Gởi tin nhắn theo nhóm', value: 'group'},
    ])
    const list = ref([
      { label: 'mở 1 đường dẫn', value: 'oa.open.url' , param: 1},
      { label: 'gởi 1 tin nhắn đến OA', value: 'oa.query.show', param: 2},
      { label: 'gởi 1 tin nhắn ẩn đến OA', value: 'oa.query.hide', param: 3},
      { label: 'mở ứng dụng gởi sms với nội dung và sdt cho sẵn', value: 'oa.open.sms', param: 4},
      { label: 'mở ứng dụng gọi điện thoại với số cho sẵn', value: 'oa.open.phone', param: 5},
    ]);
    const listdefault = ref([
      { label: 'mở 1 đường dẫn', value: 'oa.open.url' , param: 1},
    ]);
    const payload1 = ref({content: "", phone_code: ""});
    const payload2 = ref({content: "", phone_code: ""});
    const payload3 = ref({content: "", phone_code: ""});
    const payload4 = ref({content: "", phone_code: ""});
    const listCustomer = ref([] as ListCustomer[]);
    const listProvince = ref([] as Province[]);
    const province = ref();
    const province2 = ref();
    const listImageFull = ref([] as ListImageFull[]);
    const listImageFullTemp = ref([] as ListImageFull[]);
    const listGroup = ref([] as Group[]);
    const idGroup = ref(0);
    const idGroup2 = ref(0);

    if(!(!!store.state.token)){
      console.log("@@@@@@@@@@@@@@@@#################### send message: ");
      router.push({
        name: 'home'
      });
    }
    // const valid = computed(()=> action.value && message.value.mess);
    const valid = () => {
      return action.value && message.value.mess;
    };
    const valid2 = () => {
      return action2.value && element1.value.title && element1.value.subtitle && element1.value.image_url && element1.value.url;
    };
    element1.value.type = '1';

    ZaloRepository.listGroup()
        .then((response) => {
          listGroup.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:'Lỗi không tìm thấy group',
            life: 2000
          });
        });
    // const returnValue = (st: any) => {
    //   const value = ref();
    //   value.value = list.value.filter(x => x.param == st);
    //   return value.value.value;
    // }
    ZaloRepository.getProvince()
        .then((response) => {
          listProvince.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:'Lỗi khi tải địa điểm',
            life: 2000
          });
        });
    ZaloRepository.getListCustomer()
        .then((response) => {
          listCustomer.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:'Lỗi khi tải danh sách khách hàng',
            life: 2000
          });
        });
    const doSendMessage = () => {
      if((listIdMessage.value.length < 1) && (action.value == "customer"))
      {
        toast.add({
          severity: 'error',
          summary: 'Lỗi',
          detail:'Vui lòng chọn danh sách khách hàng cần gởi',
          life: 3000
        });
      }
      else
      {
        message.value.userid = "";
        listIdMessage.value.forEach(x => {
          message.value.userid = message.value.userid + x.id + ",";
        });
        if(action.value == "all")
        {
          message.value.userid = "all";
        }
        else if(action.value == "gender")
        {
          message.value.userid = "gender,";
          if(gender.value == "Nam") {
            message.value.userid = message.value.userid + "1";
          }
          else {
            message.value.userid = message.value.userid + "0";
          }
        }
        else if(action.value == "age")
        {
          message.value.userid = "age,";
          message.value.userid = message.value.userid + age.value[0]+",";
          message.value.userid = message.value.userid + age.value[1];
        }
        else if(action.value == "location")
        {
          message.value.userid = "location," + province.value;
        }
        else if(action.value == "group")
        {
          message.value.userid = "group," + idGroup.value;
        }
        loadingBar.value = true;
        ZaloRepository.sendMessage(message.value)
            .then((response) => {
              const temp = ref({} as Message);
              message.value = temp.value;
              toast.add({
                severity: 'success',
                summary: 'Gởi tin',
                detail: 'Gởi tin nhắn thành công',
                life: 2000
              });
              loadingBar.value = false;
            })
            .catch(err => {
              loadingBar.value = false;
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            });
      }
      };

    const doSendMessageWithAttachment = () => {
      console.log("@@@@@@@@@@@@@@@@#################### doSendMessageWithAttachment listId lenght: " + listId.value.length);
      console.log("@@@@@@@@@@@@@@@@#################### doSendMessageWithAttachment listId: " + JSON.stringify(listId.value));
      if((listId.value.length < 1) && (action2.value == "customer"))
      {
        toast.add({
          severity: 'error',
          summary: 'Lỗi',
          detail:'Vui lòng chọn danh sách khách hàng cần gởi',
          life: 3000
        });
      }
      else
      {
        messageWithAttachment.value.userid = "";
        listId.value.forEach(x => {
          messageWithAttachment.value.userid = messageWithAttachment.value.userid + x.id + ",";
        });
        if(action2.value == "all")
        {
          messageWithAttachment.value.userid = "all";
        }
        else if(action2.value == "gender")
        {
          messageWithAttachment.value.userid = "gender,";
          if(gender2.value == "Nam") {
            messageWithAttachment.value.userid = messageWithAttachment.value.userid + "1";
          }
          else {
            messageWithAttachment.value.userid = messageWithAttachment.value.userid + "0";
          }
        }
        else if(action2.value == "age")
        {
          messageWithAttachment.value.userid = "age,";
          messageWithAttachment.value.userid = messageWithAttachment.value.userid + age2.value[0]+",";
          messageWithAttachment.value.userid = messageWithAttachment.value.userid + age2.value[1];
        }
        else if(action2.value == "location")
        {
          messageWithAttachment.value.userid = "location," + province2.value;
        }
        else if(action2.value == "group")
        {
          messageWithAttachment.value.userid = "group," + idGroup2.value;
        }
        loadingBar.value = true;
        const elements = ref([] as ElementParamater[]);
        if((element2.value.type == "4")||(element2.value.type == "5")) element2.value.payload = payload1.value;
        if((element3.value.type == "4")||(element3.value.type == "5")) element3.value.payload = payload2.value;
        if((element4.value.type == "4")||(element4.value.type == "5")) element4.value.payload = payload3.value;
        if((element5.value.type == "4")||(element5.value.type == "5")) element5.value.payload = payload4.value;
        if(element1.value.type != null)
          elements.value.push(element1.value);
        if(element2.value.type != null)
          elements.value.push(element2.value);
        if(element3.value.type != null)
          elements.value.push(element3.value);
        if(element4.value.type != null)
          elements.value.push(element4.value);
        if(element5.value.type != null)
          elements.value.push(element5.value);
        const elementstemp = ref([] as ElementParamater[]);
        elements.value.forEach(x => {
          elementstemp.value.push(
              {
                title: x.title,
                subtitle: x.subtitle,
                image_url: x.image_url,
                type: x.type,
                url: x.url,
                payload: x.payload
              }
          )
        })
        elementstemp.value.forEach(x => {
          list.value.filter(y => {if(y.param.toString() == x.type) x.type = y.value});
        });
        console.log("@@@@@@@@@@@@@@@@#################### doSendMessageWithAttachment elementstemp: " + JSON.stringify(elementstemp.value));
        messageWithAttachment.value.elements = elementstemp.value;
        console.log("@@@@@@@@@@@@@@@@#################### doSendMessageWithAttachment messageWithAttachment: " + JSON.stringify(messageWithAttachment.value));
        if(element1.value.subtitle.length > 500)
        {
          loadingBar.value = false;
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:'Nội dung khung chính vượt quá 500 ký tự, vui lòng rút gọn lại',
            life: 3000
          });
        }
        else
        {
          ZaloRepository.sendMessageWithAttachmentList(messageWithAttachment.value)
              .then((response) => {
                loadingBar.value = false;
                const temp = ref({} as MessageWithAttachment);
                messageWithAttachment.value = temp.value;
                toast.add({
                  severity: 'success',
                  summary: 'Gởi tin',
                  detail: 'Gởi tin nhắn thành công',
                  life: 2000
                });
                console.log("@@@@@@@@@@@@@@@@#################### doSendMessageWithAttachment messageWithAttachmentaaaaaa: " + JSON.stringify(messageWithAttachment.value));
              })
              .catch(err => {
                loadingBar.value = false;
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail: err.response.data,
                  life: 2000
                });
                console.log("@@@@@@@@@@@@@@@@#################### doSendMessageWithAttachment messageWithAttachmentbbbbbb: " + JSON.stringify(messageWithAttachment.value));
              });
        }
      }

    };
    const products = ref([] as ListImage[]);
    const layout = ref('grid');
    const show = ref(false);
    const getListImage = () => {
      ZaloRepository.getListImage()
          .then((response) => {
            products.value = response.data;
            products.value.forEach(x => {
              listImageFull.value.push({
                link: x.link,
                fileName: x.link.split("\\")[1],
                //fileName: x.link,
                delete: false
              })
            });
            // listImageFull.value.forEach(x =>{
            //   x.link = x.link.replace("wwwroot/UploadFolder\\", "UploadFolder/");
            // });
            listImageFullTemp.value = listImageFull.value;
            products.value.forEach(x => {
              x.link = window.location.href.split(window.location.pathname)[0] + "/" + x.link.replace("\\", "/");
            })
          })
          .catch(err => {
            // toast.add({
            //   severity: 'error',
            //   summary: 'Lỗi',
            //   detail:'Lỗi không tìm thấy hình ảnh',
            //   life: 2000
            // });
          });
    }

    getListImage();
    const index = ref(0);

    const imageClick = (link: string) => {
      console.log("############$$$$$$$$$$$$$$$$$$$$$ imageClick: " + link);
      show.value = false;
      link = link.replace("\\" , "/");
      console.log("############$$$$$$$$$$$$$$$$$$$$$ imageClick22222: " + link);
      link = window.location.href.split(window.location.pathname)[0] + "/" + link.replace("\\", "/");
      link = link.replaceAll(" ","%20");
      if(index.value == 2)
      {
        element2.value.image_url = link
      }
      else if(index.value == 3)
      {
        element3.value.image_url = link;
      }
      else if(index.value == 4)
      {
        element4.value.image_url = link;
      }
      else if(index.value == 5)
      {
        element5.value.image_url = link;
      }
      else if(index.value == 1)
      {
        element1.value.image_url = link;
      }
    }
    const showListImage = (i) => {
      show.value = true;
      index.value = i;
    }
    const search = (event) => {
      listImageFull.value = listImageFullTemp.value.filter(x => x.fileName.includes((find.value)));
      console.log("$$$$$$$$$$$$$$$ search listImageFull: " + JSON.stringify(listImageFull.value.filter(x => x.delete == true)));
    }

    const deleteImage = () => {
      console.log("$$$$$$$$$$$$$$$ search event delectImage: ");
      const listDelect = ref([] as ListImage[]);
      listImageFull.value.forEach(x => {
        if(x.delete)
          listDelect.value.push({link: x.fileName});
      });
      ZaloRepository.deleteImageFile(listDelect.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Xóa',
              detail:'Xóa thành công hình ảnh',
              life: 2000
            });
            listImageFullTemp.value = listImageFull.value.filter(x => x.delete == false);
            listImageFull.value = listImageFullTemp.value;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi xóa hình ảnh không thành công',
              life: 2000
            });
          });
    }
    const validImageSelect = () => {
      console.log("$$$$$$$$$$$$$$$ search event validImageSelect: ");
      if(listImageFull.value.filter(x => x.delete == true).length > 0)
      {
        console.log("$$$$$$$$$$$$$$$ search event validImageSelect true: ");
        return true;
      }
      else
      {
        console.log("$$$$$$$$$$$$$$$ search event validImageSelect false: ");
        return false;
      }
    };

    return {
      doSendMessage,
      valid,
      message,
      messageWithAttachment,
      doSendMessageWithAttachment,
      element1,
      element2,
      element3,
      element4,
      element5,
      list,
      payload1,
      payload2,
      payload3,
      payload4,
      listdefault,
      loadingBar,
      listCustomer,
      listId,
      listIdMessage,
      lsAction,
      action,
      gender,
      age,
      listProvince,
      province,
      province2,
      action2,
      gender2,
      age2,
      valid2,
      products,
      layout,
      showListImage,
      imageClick,
      show,
      listImageFull,
      find,
      search,
      validImageSelect,
      deleteImage,
      listGroup,
      idGroup,
      idGroup2,
    }
  }
}

